import { Brand } from "@/api/generated/BMWInterfaces"
import NioMinusIcon from "@/components/shared/icons/NioMinusIcon"
import NioPlusIcon from "@/components/shared/icons/NioPlusIcon"
import LinkWithParams from "@/lib/common/LinkWithParams"
import { HeaderLinkListDesktop, LinksType, NestedNavigationLinksType } from "@/lib/common/SiteLinks"
import { buttonMenuClick } from "@/lib/gtm"
import styled from "@emotion/styled"
import { ButtonBase, Typography } from "@mui/material"
import { Fragment, useMemo, useState } from "react"

const StyledLayout = styled.div`
  display: flex;
  gap: 32px;
`

const StyledLinkButton = styled(ButtonBase)<{ selected?: boolean }>`
  display: flex;
  justify-content: center;
  cursor: pointer;
  svg {
    margin-left: 4px;
  }
`

type HeaderLinkListProps = {
  setSubLinks: React.Dispatch<React.SetStateAction<LinksType[] | null>>
  subCollapseRef: React.RefObject<HTMLDivElement>
  openCollapse: () => void
  closeCollapse: () => void
  brands: Brand[]
}

const HeaderLinkList = ({
  setSubLinks,
  subCollapseRef,
  closeCollapse,
  openCollapse,
  brands,
}: HeaderLinkListProps): React.ReactElement => {
  const [selectedLink, setSelectedLink] = useState<string | null>(null)
  const linkList: NestedNavigationLinksType[] = useMemo(() => {
    const brandLinks: LinksType[] = brands.map((brand) => ({
      title: brand.name,
      link: `/search?brandId=${brand.id}`,
    }))

    return HeaderLinkListDesktop.map((item) => {
      if (item.label === "allModels") {
        return {
          ...item,
          links: brandLinks,
        }
      }
      return item
    })
  }, [brands])
  return (
    <StyledLayout>
      {linkList.map((item, index) => (
        <Fragment key={index}>
          {item.links.length === 1 ? (
            <LinkWithParams
              href={item.links[0]?.link ? item.links[0].link : "/"}
              target={item.links[0]?.blank === true ? "_blank" : undefined}
              style={{ textDecoration: "none" }}
              onClick={() => {
                buttonMenuClick(item.title)
              }}
            >
              <Typography
                variant="body2"
                onClick={() => {
                  setSubLinks(null)
                  setSelectedLink(item.title)
                }}
              >
                {item.title}
              </Typography>
            </LinkWithParams>
          ) : (
            <StyledLinkButton
              selected={item.title === selectedLink}
              onClick={() => {
                if (item.title === selectedLink) {
                  setSubLinks(null)
                  setSelectedLink(null)
                  closeCollapse()
                } else {
                  setSubLinks(item.links)
                  setSelectedLink(item.title)
                  openCollapse()
                }
                buttonMenuClick(item.title)
              }}
              aria-expanded={item.title === selectedLink ? true : false}
              onKeyDown={(e) => {
                item.title === selectedLink && e.key === "Tab" && subCollapseRef?.current?.focus()
              }}
            >
              {item.title === selectedLink ? <NioMinusIcon /> : <NioPlusIcon />}
              <Typography>{item.title}</Typography>
            </StyledLinkButton>
          )}
        </Fragment>
      ))}
    </StyledLayout>
  )
}

export default HeaderLinkList
