import { Brand } from "@/api/generated/BMWInterfaces"
import HamburgerIcon from "@/components/shared/icons/HamburgerIcon"
import NioTextIcon from "@/components/shared/icons/NioTextIcon"
import XIcon from "@/components/shared/icons/XIcon"
import { HomePageContainer } from "@/components/shared/shared"
import LinkWithParams from "@/lib/common/LinkWithParams"
import { Desktop, Mobile } from "@/lib/common/MediaQueries"
import { LinksType } from "@/lib/common/SiteLinks"
import styled from "@emotion/styled"
import { AppBar, Box, IconButton, Toolbar } from "@mui/material"
import { useRef, useState } from "react"
import HeaderLinksList from "./HeaderLinksList"
import HeaderSubCollapse from "./HeaderSubCollapse"
import SideDrawer from "./SideDrawer"

type HeaderProps = {
  brands: Brand[]
}

const StyledAppBar = styled(AppBar)<{ open: boolean }>`
  justify-content: center;
  transition: transform 330s ease-in-out;
  background-color: white;
  z-index: 1002 !important;
`

const StyledContainer = styled(HomePageContainer)`
  height: 60px;
  transition: height 0.33s linear;
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 80px;
  }
`
const StyledToolbar = styled(Toolbar)`
  height: 100%;
  width: 100%;
  justify-content: space-between;
  min-height: auto;
  align-items: center;
`

const IconWrapper = styled(Box)`
  width: 32px;
  height: 32px;
  margin-left: 16px;
  &:focus {
    border: 2px dashed white;
    outline: 3px dashed #0e3487;
    outline-offset: 2px;
  }
`

const StyledIconButton = styled(IconButton)`
  path {
    stroke: #231f20;
  }
`

const StyledLogoBox = styled(Box)<{ open?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${(p) => p.theme.breakpoints.up("md")} {
    transform: scale(1);
  }
`

const Header = ({ brands }: HeaderProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [collapse, toggleCollapse] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [subLinks, setSubLinks] = useState<LinksType[] | null>(null)
  const subCollapseRef = useRef(null)

  const openCollapse = (): void => toggleCollapse(true)
  const closeCollapse = (): void => toggleCollapse(false)
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return
    }

    setIsDrawerOpen(open)
  }
  return (
    <StyledAppBar open={isDrawerOpen}>
      <StyledContainer>
        <StyledToolbar disableGutters>
          <Mobile>
            <IconWrapper>
              <StyledIconButton
                onClick={toggleDrawer(!isDrawerOpen)}
                aria-label={isDrawerOpen ? "סגירת תפריט" : "לחץ לפתיחת תפריט ראשי"}
                style={{ padding: 0 }}
                aria-expanded={isDrawerOpen ? true : false}
              >
                {isDrawerOpen ? <XIcon /> : <HamburgerIcon />}
              </StyledIconButton>
            </IconWrapper>
          </Mobile>
          <Desktop>
            <HeaderLinksList
              setSubLinks={setSubLinks}
              subCollapseRef={subCollapseRef}
              openCollapse={openCollapse}
              closeCollapse={closeCollapse}
              brands={brands}
            />
          </Desktop>

          <LinkWithParams href="/" aria-label="לוגו ניו דף הבית">
            <StyledLogoBox open={isDrawerOpen}>
              <NioTextIcon />
            </StyledLogoBox>
          </LinkWithParams>
        </StyledToolbar>
      </StyledContainer>
      <Desktop aboveTheFold>
        <HeaderSubCollapse subLinks={subLinks} subCollapseRef={subCollapseRef} />
      </Desktop>
      <Mobile>
        <SideDrawer
          isOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          setIsDrawerOpen={setIsDrawerOpen}
          brands={brands}
        />
      </Mobile>
    </StyledAppBar>
  )
}

export default Header
