import { Brand } from "@/api/generated/BMWInterfaces"
import NioMinusIcon from "@/components/shared/icons/NioMinusIcon"
import NioPlusIcon from "@/components/shared/icons/NioPlusIcon"
import XIcon from "@/components/shared/icons/XIcon"
import { HEADER_HEIGHT_M, StyledCol } from "@/components/shared/shared"
import LinkWithParams from "@/lib/common/LinkWithParams"
import { LinksType, NestedNavigationLinksType, SideDrawerMobile } from "@/lib/common/SiteLinks"
import { buttonMenuClick } from "@/lib/gtm"
import colors from "@/lib/theme/colors"
import styled from "@emotion/styled"
import { Button, ButtonBase, Collapse, Drawer, Typography } from "@mui/material"
import React, { useMemo, useState } from "react"

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    padding-top: ${HEADER_HEIGHT_M}px;
    padding-bottom: 32px;
    height: 100%;
    background: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    ${(p) => p.theme.breakpoints.down("sm")} {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
  }
  z-index: 1001 !important;
`
const StyledDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const StyledLinkButton = styled(Button)<{ selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  background: none;
  border: none;
  p {
    font-weight: ${(p) => (p.selected ? 500 : 400)};
  }
  &:hover {
    background: none;
    border: none;
  }
  padding: 0px;
  padding-right: 40px;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-left: 4px;
    path {
      stroke: ${colors.black};
    }
  }
`
const StyledTypographyRtl = styled(Typography)`
  padding-right: 40px;
`
const StyledLinkBtn = styled(ButtonBase)`
  padding: 14px 40px 14px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`
const StyledButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  &:focus-visible {
    border: 2px dashed white;
    outline: 3px dashed #0e3487;
    outline-offset: 2px;
  }
  width: 50px;
  height: 50px;
  /* position: absolute;
  margin-top: -45px;
  right: 40px;
  z-index: 10; */
`
type SideDrawerProps = {
  isOpen: boolean
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  brands: Brand[]
}

const SideDrawer = ({
  isOpen,
  toggleDrawer,
  brands,
  setIsDrawerOpen,
}: SideDrawerProps): React.ReactElement => {
  const [open, setOpen] = React.useState<string | false>(false)
  const [isVisible, setIsVisible] = useState(false)

  const handleClick = (panel: string): void => {
    setOpen(panel !== open ? panel : false)
  }

  React.useEffect(() => {
    setIsVisible(false)
    if (isOpen) {
      return
    }
    setOpen(false)
  }, [isOpen])
  const linkList: NestedNavigationLinksType[] = useMemo(() => {
    const brandLinks: LinksType[] = brands.map((brand) => ({
      title: brand.name,
      link: `/search?brandId=${brand.id}`,
    }))

    return SideDrawerMobile.map((item) => {
      if (item.label === "allModels") {
        return {
          ...item,
          links: brandLinks,
        }
      }
      return item
    })
  }, [brands])
  return (
    <StyledDrawer anchor={"top"} open={isOpen} onClose={toggleDrawer(false)}>
      <StyledDrawerWrapper role="presentation">
        <StyledCol $gap="32px" $notCenter $fullWidth $mt="48px" $pb="40px">
          {isVisible && (
            <StyledButton
              onClick={() => {
                setOpen(false)
                setIsDrawerOpen(false)
              }}
              onKeyDown={(e) => e.key === "Tab" && setIsVisible(false)}
              aria-label={"סגירת תפריט"}
              aria-expanded="true"
              tabIndex={0}
            >
              <XIcon />
            </StyledButton>
          )}
          {linkList.map((item, index) => (
            <div
              key={index}
              onKeyDown={(e) => {
                setIsVisible(e.shiftKey && e.key === "Tab" && index === 0)
              }}
              // Add your action here
            >
              {item.links.length === 1 ? (
                <>
                  <LinkWithParams
                    href={item?.links[0]?.link ? item.links[0]?.link : ""}
                    // openInNewTab={item?.links[0].blank}
                    style={{ textDecoration: "none" }}
                    onClick={(e) => {
                      buttonMenuClick(item.title)
                      toggleDrawer(false)(e)
                    }}
                  >
                    <StyledTypographyRtl variant="body1" color={colors.black}>
                      {item.title}
                    </StyledTypographyRtl>
                  </LinkWithParams>
                </>
              ) : (
                <>
                  <StyledLinkButton
                    onClick={() => {
                      handleClick(item.title)
                      buttonMenuClick(item.title)
                    }}
                    selected={item.title === open}
                    disableFocusRipple={false}
                    disableRipple={false}
                    aria-expanded={item.title === open ? true : false}
                  >
                    {item.title === open ? <NioMinusIcon /> : <NioPlusIcon />}
                    <Typography variant={open ? "subtitle1" : "body1"} color={colors.black}>
                      {item.title}
                    </Typography>
                  </StyledLinkButton>
                  <Collapse in={item.title === open} timeout="auto" unmountOnExit>
                    {/* <List component="div" disablePadding> */}
                    {item.links.map((item, index) => (
                      <LinkWithParams
                        style={{ textDecoration: "none" }}
                        href={item.link}
                        passHref
                        key={index}
                        onClick={toggleDrawer(false)}
                      >
                        <StyledLinkBtn onClick={toggleDrawer(false)}>
                          <Typography variant="body1" color={colors.black}>
                            {item.title}
                          </Typography>
                        </StyledLinkBtn>
                      </LinkWithParams>
                    ))}
                    {/* </List> */}
                  </Collapse>
                </>
              )}
            </div>
          ))}
        </StyledCol>
      </StyledDrawerWrapper>
    </StyledDrawer>
  )
}

export default SideDrawer
